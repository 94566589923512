<template>
  <div class="q-mt-lg q-pa-md flex row justify-center">
    <q-form class="q-gutter-md myprofile">
      <div class="">
        <input
          type="file"
          ref="cover_img"
          @change="upload_cover"
          class="hidden"
          accept="image/*"
        />

        <q-img
          v-if="cover_src == null"
          class="cover-img"
          src="@/assets/placeholder.jpg"
          @click.stop="cover_img.click()"
        >
          <div class="overlay">
            <q-icon class="myicon absolute-center" size="xl" name="edit" />
          </div>
        </q-img>
        <q-img
          v-else
          class="cover-img"
          :src="cover_src"
          @click.stop="cover_img.click()"
        >
          <div class="overlay">
            <q-icon class="myicon absolute-center" size="xl" name="edit" />
          </div>
        </q-img>
        <p class="q-ma-sm text-body">{{ $t("upload_cover") }}</p>
      </div>

      <div class="">
        <input
          type="file"
          ref="avatar_img"
          @change="upload_avatar"
          class="hidden"
          accept="image/*"
        />

        <q-img
          v-if="avatar_src == null"
          class="avatar-img"
          src="@/assets/placeholder.jpg"
          @click.stop="avatar_img.click()"
        >
          <div class="overlay">
            <q-icon class="myicon absolute-center" size="xl" name="edit" />
          </div>
        </q-img>
        <q-img
          v-else
          class="avatar-img"
          :src="avatar_src"
          @click.stop="avatar_img.click()"
        >
          <div class="overlay">
            <q-icon class="myicon absolute-center" size="xl" name="edit" />
          </div>
        </q-img>
        <p class="q-ma-sm text-body">{{ $t("upload_avatar") }}</p>
      </div>

      <q-input
        filled
        v-model="nickname"
        :label="$t('nickname')"
        :hint="$t('choose_your_nickname')"
        @change="autosave"
      />
      <q-input
        filled
        type="textarea"
        :min-rows="7"
        v-model="bio"
        :label="$t('bio')"
        @change="autosave"
        :hint="$t('your_bio')"
      />
      <q-input
        filled
        v-model="website"
        :label="$t('website')"
        @change="autosave"
        :hint="$t('set_your_website')"
      />

      <q-input
        filled
        v-model="facebook"
        :label="$t('facebook')"
        @change="autosave"
        :hint="$t('set_your_facebook')"
      />
      <q-input
        filled
        v-model="twitter"
        :label="$t('twitter')"
        @change="autosave"
        :hint="$t('set_your_twitter')"
      />
      <q-input
        filled
        v-model="instagram"
        :label="$t('instagram')"
        @change="autosave"
        :hint="$t('set_your_instagram')"
      />

      <q-input
        filled
        v-model="video_link"
        :label="$t('video_link')"
        @change="autosave"
        :hint="$t('set_your_video')"
      />
      <q-input
        filled
        v-model="shop_link"
        :label="$t('shop_link')"
        @change="autosave"
        :hint="$t('set_your_shop')"
      />
      <q-input
        filled
        v-model="blog_link"
        :label="$t('blog_link')"
        @change="autosave"
        :hint="$t('set_your_blog')"
      />
      <!-- <q-input
        filled
        v-model="telegram"
        label="Telegram account"
        @change="autosave"
        hint="Set your Telegram account"
      /> -->
      <q-separator class="q-mt-xl q-mb-xl" />

      <q-field filled readonly :hint="$t('profile')">
        <div class="self-center" tabindex="0">
          <a
            :href="
              utype == 'seller' ? '/shop/' + user.id : '/profile/' + user.id
            "
          >
            {{ user.id }}
          </a>
        </div>
      </q-field>

      <!-- <q-input
        filled
        readonly
        v-model="user.user_metadata.wallet"
        :hint="$t('wallet_address')"
      >
      </q-input>
      <q-input
        filled
        readonly
        v-model="user.user_metadata.key"
        :hint="$t('wallet_key')"
        @click="show_type = 'text'"
        :type="show_type"
      >
        <template v-slot:append>
          <q-icon name="fas fa-eye" />
        </template>
      </q-input>
      <q-input filled readonly v-model="balance" :hint="$t('matic_balance')" /> -->
      <q-input filled readonly v-model="utype" :hint="$t('user_type')">
        <template v-slot:after>
          <q-btn
            v-if="utype == 'customer'"
            color="blue"
            @click="confirm = true"
          >
            {{ $t("upgrade") }}
          </q-btn>
          <q-btn v-else color="blue" @click="downgrade_confirm = true">
            {{ $t("downgrade") }}
          </q-btn>
        </template>
      </q-input>

      <q-field filled readonly :hint="$t('referrer')">
        <div class="self-center" tabindex="0">
          <a :href="utype == 'seller' ? '/shop/' + rid : '/profile/' + rid">
            {{ rid }}</a
          >
        </div>
      </q-field>
      <q-separator class="q-mt-xl q-mb-xl" />

      <q-input
        filled
        v-model="new_email"
        :hint="'Current Email: ' + user.email"
      >
        <template v-slot:after>
          <q-btn color="blue" @click="set_new_email()">
            {{ $t("Set New Email") }}
          </q-btn>
        </template>
      </q-input>
    </q-form>
  </div>
  <q-dialog v-model="downgrade_confirm" persistent>
    <q-card>
      <q-card-section>
        <div class="text-h6">Downgrade Confirm</div>
      </q-card-section>
      <q-card-section class="text-body"
        >You will downgrade to a Customer!</q-card-section
      >
      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="grey" v-close-popup />
        <q-btn
          flat
          label="Downgrade"
          color="orange"
          v-close-popup
          @click="downgrade"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="confirm" persistent>
    <q-card>
      <q-card-section>
        <div class="text-h6">Upgrade Confirm</div>
      </q-card-section>
      <q-card-section class="text-body"
        >You will upgrade to a Seller!</q-card-section
      >
      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="grey" v-close-popup />
        <q-btn
          flat
          label="Upgrade"
          color="orange"
          v-close-popup
          @click="upgrade"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<style lang="sass" scoped>
.myprofile
  width: 100%
  max-width: 970px
  margin-bottom: 3rem
.cover-img
  cursor: pointer
  height: 250px
  :hover .overlay
    display: block
    background: rgba(0, 0, 0, .3)
    opacity: 1

.avatar-img
  border-radius: 75px
  height: 150px
  width: 150px
  cursor: pointer
  :hover .overlay
    display: block
    background: rgba(0, 0, 0, .3)
    opacity: 1

.overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  opacity: 0
  background: rgba(0, 0, 0, 0)
  transition: background 0.5s ease
  transition: opacity 0.5s ease
</style>

<script>
import { ref, onBeforeMount } from "vue";
import { supabase as s } from "../supabase";
import { w3 } from "../web3.js";
import { useQuasar } from "quasar";
export default {
  name: "MySettings",
  setup() {
    const user = s.auth.user();
    console.log(user);
    const myprofile = ref();
    const nickname = ref("");
    const cover_img = ref(null);
    const cover_src = ref(null);
    const avatar_img = ref(null);
    const avatar_src = ref(null);
    const facebook = ref("");
    const twitter = ref("");
    const instagram = ref("");
    const telegram = ref("");
    const bio = ref("");
    const balance = ref(0);
    const website = ref("");
    const utype = ref("");
    const blog_link = ref("");
    const video_link = ref("");
    const shop_link = ref("");
    const rid = ref("");
    const new_email = ref("");
    w3.eth.getBalance(user.user_metadata.wallet).then((b) => {
      balance.value = w3.utils.fromWei(b);
    });
    const q = useQuasar();
    onBeforeMount(async () => {
      let { data: profile, error } = await s
        .from("profiles")
        .select("*")
        .eq("id", user.id);
      let p = profile[0];
      console.log(p);
      nickname.value = p.nickname;
      cover_src.value = p.cover;
      avatar_src.value = p.avatar;
      facebook.value = p.facebook;
      twitter.value = p.twitter;
      instagram.value = p.instagram;
      telegram.value = p.telegram;
      website.value = p.website;
      bio.value = p.bio;
      utype.value = p.utype;
      blog_link.value = p.blog_link;
      shop_link.value = p.shop_link;
      video_link.value = p.video_link;
      rid.value = p.rid;
    });
    return {
      show_type: ref("password"),
      myprofile,
      user,
      balance,
      nickname,
      cover_img,
      cover_src,
      avatar_img,
      avatar_src,
      facebook,
      twitter,
      instagram,
      telegram,
      bio,
      website,
      blog_link,
      shop_link,
      video_link,
      q,
      rid,
      confirm: ref(false),
      downgrade_confirm: ref(false),
      utype,
      new_email,
    };
  },
  methods: {
    async upload_cover(evt) {
      const files = evt.target.files;
      try {
        if (!files || files.length === 0) {
          throw new Error("You must select an image to upload.");
        }

        const file = files[0];
        console.log("file size:",file.size)
        if (file.size > 1024*256) {
          throw new Error("Image size too big, maximum 256KB. try https://www.jpeg.io/ reduce your image size.");
        }             
        const ext = file.name.split(".").pop();
        const fname = `${Math.random()}.${ext}`;
        const fpath = "cover/" + `${fname}`;
        let { error: uploadError } = await s.storage
          .from("cnft")
          .upload(fpath, file);
        if (uploadError) throw uploadError;
        const { publicURL, error } = await s.storage
          .from("cnft")
          .getPublicUrl(fpath);
        if (error) console.log(error);
        this.cover_src = publicURL;
        this.autosave();
      } catch (error) {
        alert(error.message);
      }
    },

    async upload_avatar(evt) {
      const files = evt.target.files;
      try {
        if (!files || files.length === 0) {
          throw new Error("You must select an image to upload.");
        }

        const file = files[0];
        const ext = file.name.split(".").pop();
        const fname = `${Math.random()}.${ext}`;
        const fpath = "avatar/" + `${fname}`;
        let { error: uploadError } = await s.storage
          .from("cnft")
          .upload(fpath, file);
        if (uploadError) throw uploadError;
        const { publicURL, error } = await s.storage
          .from("cnft")
          .getPublicUrl(fpath);
        if (error) console.log(error);
        this.avatar_src = publicURL;
        this.autosave();
      } catch (error) {
        alert(error.message);
      }
    },
    async downgrade() {
      const { data, error } = await s
        .from("profiles")
        .update({ utype: "customer" })
        .match({ id: this.user.id });
      if (error) console.log(error);
      console.log(data);
      if (data) {
        this.utype = "customer";
        this.q.notify(
          "You are a seller now! Go to my shop to create your NFT for sale!"
        );
        this.$router.push("/mysettings");
      }
    },
    async upgrade() {
      const { data, error } = await s
        .from("profiles")
        .update({ utype: "seller" })
        .match({ id: this.user.id });
      if (error) console.log(error);
      console.log(data);
      if (data) {
        this.utype = "seller";
        this.q.notify(
          "You are a seller now! Go to my shop to create your NFT for sale!"
        );
        this.$router.push("/mysettings");
      }
    },
    async autosave() {
      // generate json
      const user_profile = {
        cover: this.cover_src,
        avatar: this.avatar_src,
        nickname: this.nickname,
        bio: this.bio,
        facebook: this.facebook,
        twitter: this.twitter,
        instagram: this.instagram,
        telegram: this.telegram,
        website: this.website,
        video_link: this.video_link,
        shop_link: this.shop_link,
        blog_link: this.blog_link,
      };
      console.log(user_profile);
      let { data, error } = await s
        .from("profiles")
        .update(user_profile)
        .match({ id: this.user.id });
      if (error) console.log(error);
      this.q.notify("Autosave your profile success!");
    },

    async set_new_email() {
      this.q.loading.show({
        message: "Set your new email",
      });

      const { user, error } = await s.auth.update({ email: this.new_email });
      if (error) {
        console.log(error);
        this.q.loading.hide();
        this.q.notify(error);
      } else {
        console.log(user)
        this.q.notify(
          "Set new email success, please check your email to login."
        );
        this.q.loading.hide();
      }
    },
  },
};
</script>