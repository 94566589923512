import { render } from "./MySettings.vue?vue&type=template&id=48684b3c&scoped=true"
import script from "./MySettings.vue?vue&type=script&lang=js"
export * from "./MySettings.vue?vue&type=script&lang=js"

import "./MySettings.vue?vue&type=style&index=0&id=48684b3c&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-48684b3c"

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QField from 'quasar/src/components/field/QField.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QImg,QIcon,QInput,QSeparator,QField,QBtn,QDialog,QCard,QCardSection,QCardActions});qInstall(script, 'directives', {ClosePopup});
